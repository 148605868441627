<template>
  <div class="DepartureCard">
    <section class="DepartureCard__sectionLeft">
      <span class="DepartureCard__department">Afgang fra: {{ department }}</span>
      <Time :datetime="departureDate" format="LT" class="DepartureCard__departureTime" />
    </section>
    <section class="DepartureCard__sectionRight">
      <span class="DepartureCard__topLine">{{ $t('deadline') }}</span>
      <div class="DepartureCard__bottomLine">
        <Countdown v-if="showCountdown" :to="deadlineDate" class="DepartureCard__countdown" />
        <Time :datetime="deadlineDate" format="LT" class="DepartureCard__deadline" />
      </div>
    </section>
  </div>
</template>

<script>
import Time from '@components/Time';
import Countdown from '../Countdown';

export default {
  name: 'DepartureCard',

  components: {
    Time,
    Countdown,
  },

  props: {
    department: {
      type: String,
      default: '',
    },
    departureDate: {
      type: String,
      default: '',
    },
    deadlineDate: {
      type: String,
      default: '',
    },
    showCountdown: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.DepartureCard {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .DepartureCard__sectionLeft {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
  }

  .DepartureCard__sectionRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .DepartureCard__department {
    font-size: 12px;
    color: #999;
  }

  .DepartureCard__departureTime {
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
  }

  .DepartureCard__topLine {
    white-space: nowrap;
    font-size: 12px;
    color: #999;
  }

  .DepartureCard__bottomLine {
    display: flex;
  }

  .DepartureCard__countdown {
    font-size: 12px;
    margin-right: 10px;
  }

  .DepartureCard__deadline {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
}
</style>

<i18n lang="json">
{
 "da": {
    "departure": "Afgang",
    "deadline": "Deadline for bestilling"
  }
}
</i18n>
