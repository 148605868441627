//https://xapi.thg.dk/specialdelivery/GetContainers

import { Api } from '../';

export function getContainers() {
  return new Promise((resolve, reject) => {
    Api.get(`/specialdelivery/containers`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getDepartures({ limit = 20 } = {}) {
  return new Promise((resolve, reject) => {
    Api.get(`/specialdelivery/departures/overview`, { params: { limit } })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
