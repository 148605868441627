<template>
  <ul class="PanelRefinements">
    <li
      v-for="refinement in refinements"
      :key="refinement[refinementKey]"
      class="PanelRefinements__filter"
    >
      <input
        v-if="refinement.reset"
        :id="'PanelRefinements__input' + refinement[refinementKey]"
        :checked="activeRefinements.length === 0"
        class="PanelRefinements__input"
        type="checkbox"
        @click="reset"
      />
      <input
        v-else-if="multipleChoice"
        :id="'PanelRefinements__input' + refinement[refinementKey]"
        v-model="activeRefinements"
        class="PanelRefinements__input"
        type="checkbox"
        :value="refinement"
      />
      <input
        v-else-if="!multipleChoice"
        :id="'PanelRefinements__input' + refinement[refinementKey]"
        v-model="activeRefinements"
        class="PanelRefinements__input"
        type="radio"
        :value="refinement"
      />
      <label
        :for="'PanelRefinements__input' + refinement[refinementKey]"
        class="PanelRefinements__label"
      >
        {{ refinement.label }}
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PanelRefinements',

  props: {
    refinements: {
      type: Array,
      required: true,
      validator(refinements) {
        const isValid = refinements.every(refinement => refinement.label);
        if (!isValid) console.warn('Missing "label" prop on refinement');
        return isValid;
      },
    },
    refinementKey: {
      type: String,
      default: 'id',
    },
    multipleChoice: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
  },

  computed: {
    activeRefinements: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    reset(e) {
      if (e.currentTarget.checked) this.activeRefinements = [];
      //Prevents deselecting of the refinement
      else e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.PanelRefinements {
  display: flex;
  gap: 1.5em;
  margin: 0;

  .PanelRefinements__filter:hover {
    color: #000000;
  }

  .PanelRefinements__input:checked + .PanelRefinements__label {
    color: #000000;
    font-weight: bold;
  }

  .PanelRefinements__filter {
    width: fit-content;
    color: #808080;
    font-size: 20px;
  }

  .PanelRefinements__input {
    display: none;
  }

  .PanelRefinements__label {
    font-weight: normal;
    user-select: none;
    cursor: pointer;
  }
}
</style>
