<template>
  <component
    :is="tag"
    :class="[
      'ColonListItem',
      highlighted && 'ColonListItem--highlighted',
      grey && 'ColonListItem--grey',
      ellipsis && 'ColonListItem--ellipsis',
    ]"
  >
    <span class="ColonListItem__label">{{ label + ':' }}</span>
    <template v-if="format === FormatTypes.DATE">
      <Time :datetime="value" :format="dateFormat" class="ColonListItem__value" />
    </template>
    <template v-else-if="format === FormatTypes.CURRENCY">
      <Price :value="value" class="ColonListItem__value" />
    </template>
    <template v-else-if="format === FormatTypes.PERCENTAGE">
      {{ Math.round(value * 100) }}%
    </template>
    <template v-else-if="format === FormatTypes.URL">
      <a :href="value" class="ColonListItem__value" target="_blank" :title="urlPlaceholder">
        {{ urlPlaceholder }}
      </a>
    </template>
    <span v-else class="ColonListItem__value">{{ value }}</span>
  </component>
</template>

<script>
import Time from '@components/Time';
import Price from '@components/Price';

export const FormatTypes = {
  DATE: 'date',
  CURRENCY: 'currency',
  URL: 'url',
  PERCENTAGE: 'PERCENTAGE',
};

export default {
  name: 'ColonListItem',

  components: {
    Time,
    Price,
  },

  enums: {
    FormatTypes,
  },

  props: {
    tag: {
      type: String,
      default: 'div',
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    format: {
      type: String,
      default: '',
    },
    urlPlaceholder: {
      type: String,
      default: '',
    },
    dateFormat: {
      type: String,
      default: 'L',
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ColonListItem {
  display: flex;

  &--grey {
    color: #bbbbbb;
  }

  &--ellipsis .ColonListItem__value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ColonListItem__value {
    margin-left: 0.2em;
  }

  .ColonListItem__label {
    padding-right: 0.1em;
  }

  a.ColonListItem__value {
    text-decoration: underline;
  }

  a.ColonListItem__value:hover {
    color: black;
  }

  &--highlighted .ColonListItem__value {
    font-weight: bold;
  }

  a {
    color: inherit;
  }
}
</style>
