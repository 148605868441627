import { groupBy } from '@helpers/array';
import { getContainers, getDepartures } from '@services/thansen/specialdelivery';
import Progress from '@types/Progress';

export const Getters = {
  SUBSCRIBED_DEPARTURES: 'SUBSCRIBED_DEPARTURES',
  SCHEDULED_DEPARTURES: 'SCHEDULED_DEPARTURES',
  DEPARTMENT_NAMES: 'DEPARTMENT_NAMES',
  SUBSCRIBED_DEPARTURES_BY_DEPARTMENTS: 'SUBSCRIBED_DEPARTURES_BY_DEPARTMENTS',
  SCHEDULED_DEPARTURES_BY_DEPARTMENTS: 'SCHEDULED_DEPARTURES_BY_DEPARTMENTS',
  NEXT_SCHEDULED_DEPARTURES: 'NEXT_SCHEDULED_DEPARTURES',
};

export const Actions = {
  FETCH_CONTAINERS: 'FETCH_CONTAINERS',
  FETCH_DEPARTURES: 'FETCH_DEPARTURES',
};

const Mutations = {
  SET_CONTAINER_STATUS: 'SET_CONTAINER_STATUS',
  SET_CONTAINERS: 'SET_CONTAINERS',
  SET_DEPARTURE_STATUS: 'SET_DEPARTURE_STATUS',
  SET_DEPARTURES: 'SET_DEPARTURES',
};

const fetchDeparturesCooldown = 10000;

export default {
  namespaced: true,

  state: {
    containerStatus: '',
    containers: [],
    departureStatus: '',
    departures: {
      subscribed: [],
      scheduled: [],
    },
    latestDepartureUpdate: 0,
  },

  getters: {
    [Getters.SUBSCRIBED_DEPARTURES](state) {
      return state.departures.subscribed;
    },
    [Getters.SCHEDULED_DEPARTURES](state) {
      return state.departures.scheduled.filter(departure => {
        return new Date(departure.deadline) - Date.now() > 0;
      });
    },
    [Getters.DEPARTMENT_NAMES](state) {
      return Array.from(
        new Set(state.departures.subscribed.map(departure => departure.departure_department.name)),
      );
    },
    [Getters.SUBSCRIBED_DEPARTURES_BY_DEPARTMENTS](state, getters) {
      return groupBy(
        getters[Getters.SUBSCRIBED_DEPARTURES],
        departure => departure.departure_department.name,
        'name',
        'departures',
      );
    },
    [Getters.SCHEDULED_DEPARTURES_BY_DEPARTMENTS](state, getters) {
      return groupBy(
        getters[Getters.SCHEDULED_DEPARTURES],
        departure => departure.departure_department.name,
        'name',
        'departures',
      );
    },
    [Getters.NEXT_SCHEDULED_DEPARTURES](state, getters) {
      return getters[Getters.SCHEDULED_DEPARTURES_BY_DEPARTMENTS].map(department => {
        return department.departures[0];
      });
    },
  },

  actions: {
    [Actions.FETCH_CONTAINERS]({ commit }) {
      commit(Mutations.SET_CONTAINER_STATUS, Progress.WORKING);
      getContainers()
        .then(containers => {
          commit(Mutations.SET_CONTAINERS, containers);
          commit(Mutations.SET_CONTAINER_STATUS, Progress.COMPLETE);
        })
        .catch(error => {
          commit(Mutations.SET_CONTAINER_STATUS, Progress.ERROR);
        });
    },
    [Actions.FETCH_DEPARTURES]({ commit, state }, ignoreCooldown) {
      return new Promise((resolve, reject) => {
        //Don't fetch departure if not enough time has passed
        if (!ignoreCooldown && Date.now() < state.latestDepartureUpdate + fetchDeparturesCooldown) {
          resolve();
        }
        commit(Mutations.SET_DEPARTURE_STATUS, Progress.WORKING);
        getDepartures()
          .then(data => {
            commit(Mutations.SET_DEPARTURES, {
              subscribed: data.subscribed_departures,
              scheduled: data.scheduled_departures,
            });
            commit(Mutations.SET_DEPARTURE_STATUS, Progress.COMPLETE);
            resolve();
          })
          .catch(error => {
            commit(Mutations.SET_DEPARTURE_STATUS, Progress.ERROR);
            reject(error);
          });
      });
    },
  },

  mutations: {
    [Mutations.SET_CONTAINER_STATUS](state, status) {
      state.containerStatus = status;
    },
    [Mutations.SET_CONTAINERS](state, containers) {
      state.containers = containers;
    },
    [Mutations.SET_DEPARTURE_STATUS](state, status) {
      state.departureStatus = status;
    },
    [Mutations.SET_DEPARTURES](state, departures) {
      state.departures = departures;
      state.latestDepartureUpdate = Date.now();
    },
  },
};
