<template>
  <SpecialDeliveryContainer v-slot="{ containerStatus, containers }">
    <div class="SpecialDeliveryScene">
      <section class="SpecialDeliveryScene__section">
        <h3 class="SpecialDeliveryScene__headline">{{ $t('departureTimes') }}</h3>
        <SubscribedDepartures class="SpecialDeliveryScene__subscribedDepartures" />
      </section>

      <section class="SpecialDeliveryScene__section">
        <h3 class="SpecialDeliveryScene__headline">{{ $t('upcomingDelivery') }}</h3>
        <ScheduledDepartures class="SpecialDeliveryScene__scheduledDepartures" />
      </section>

      <section>
        <h3 class="SpecialDeliveryScene__headline">{{ $t('yourContainer') }}</h3>
        <ul class="SpecialDeliveryScene__containers">
          <DetailList
            v-for="item in containers"
            :key="item.container_id"
            icon="fal fa-inbox"
            :title="$t('number') + ': ' + item.container_id"
            :lines="[
              {
                label: $t('receive'),
                value: item.date_received,
                format: FormatTypes.DATE,
              },
              item.department && {
                label: $t('department'),
                value: item.department,
              },
            ]"
          />
        </ul>
        <p
          v-if="
            containers.length === 0 &&
              (containerStatus === Progress.COMPLETE || containerStatus === Progress.ERROR)
          "
        >
          {{ $t('missingBox') }}
        </p>
      </section>
    </div>
  </SpecialDeliveryContainer>
</template>

<script>
import SpecialDeliveryContainer from '@scenes/SelfserviceScene/containers/SpecialDeliveryContainer';
import SubscribedDepartures from './components/SubscribedDepartures';
import ScheduledDepartures from './components/ScheduledDepartures';
import DetailList from './components/DetailList';
import { FormatTypes } from '@scenes/SelfserviceScene/components/ColonListItem';
import Progress from '@types/Progress';

export default {
  name: 'SpecialDeliveryScene',

  components: {
    SpecialDeliveryContainer,
    SubscribedDepartures,
    ScheduledDepartures,
    DetailList,
  },

  enums: {
    FormatTypes,
    Progress,
  },
};
</script>

<style lang="scss" scoped>
.SpecialDeliveryScene {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .SpecialDeliveryScene__headline {
    padding-bottom: 20px;
  }

  .SpecialDeliveryScene__subscribedDepartures {
    font-size: 18px;
  }

  .SpecialDeliveryScene__scheduledDepartures {
    font-size: 15px;
  }

  .SpecialDeliveryScene__containers {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 40px;
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
  }
}
</style>

<i18n lang="json">
{
  "da": {
    "departureTimes": "Varetur tider",
    "upcomingDelivery": "Kommende vareture",
    "yourContainer": "Dine kasser",
    "number": "Nr", 
    "receive": "Modtaget",
    "department":"Afdeling",
    "missingBox": "Det ser ud til at du ikke har nogle kasser lige nu."
  }
}
</i18n>
