<template>
  <div class="Panel">
    <PanelRefinements
      v-if="refinements.length"
      v-model="activeRefinements"
      class="Panel__refinements"
      :refinements="refinements"
      :refinement-key="refinementKey"
      :multiple-choice="refinementMultipleChoice"
    />
    <ListTransition disableMove class="Panel__items">
      <PanelItem
        v-for="item in filteredItems"
        :key="item[itemKey]"
        :is-expanded="expandedItems[item[itemKey]]"
        :color="itemColor"
        @expand="expand"
      >
        <template #default>
          <slot
            name="itemContent"
            :item="item"
            :refinementMatches="refinementMatches[item[itemKey]]"
          />
        </template>
        <template #controls>
          <slot
            name="itemControls"
            :item="item"
            :refinementMatches="refinementMatches[item[itemKey]]"
          />
        </template>
        <template #expansion>
          <slot
            name="itemExpansion"
            :item="item"
            :refinementMatches="refinementMatches[item[itemKey]]"
          />
        </template>
      </PanelItem>
    </ListTransition>
  </div>
</template>

<script>
import Vue from 'vue';
import PanelRefinements from './components/PanelRefinements';
import PanelItem from './components/PanelItem';
import { ListTransition } from '@transitions';
import { Modes } from './types';

export default {
  name: 'Panel',

  components: {
    PanelRefinements,
    PanelItem,
    ListTransition,
  },

  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    itemColor: {
      type: String,
      default: Modes.LIGHT,
    },
    itemMultipleExpanded: {
      type: Boolean,
      default: false,
    },
    itemFirstExpanded: {
      type: Boolean,
      default: false,
    },
    refinements: {
      type: Array,
      default() {
        return [];
      },
    },
    refinementKey: {
      type: String,
      default: 'id',
    },
    refinementMultipleChoice: {
      type: Boolean,
      default: false,
    },
    refinementInclusive: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      //Its type is either an Object[] or Object depending on refinementMultipleChoice
      activeRefinements: [],
      //A dictionary that contains refinement matches for each item
      refinementMatches: {},
      //A dictionary that tracks which items are expanded
      expandedItems: this.items[0] ? { [this.items[0][this.itemKey]]: this.itemFirstExpanded } : {},
    };
  },

  computed: {
    filteredItems() {
      let items = [...this.items];
      this.clearMatches(items);
      if (Array.isArray(this.activeRefinements)) {
        items = this.filterItemsForEach(items, this.activeRefinements);
      } else {
        items = this.filterItems(items, this.activeRefinements);
      }
      return items;
    },
  },

  watch: {
    items(items) {
      const isSomeExpanded = items.some(item => this.expandedItems[item[this.itemKey]]);
      if (this.itemFirstExpanded && !isSomeExpanded && items[0]) {
        this.expandedItems = { [items[0][this.itemKey]]: true };
      }
    },
  },

  methods: {
    /**
     * This method is used by panelItems when they should expand. This method ensures that only one
     * panelItem is expanded at a time if itemMultipleExpanded === false. It uses expandedItems to
     * keep track of which panelItems are expanded.
     * @param {string} itemId is the key of the panelItem
     */
    expand(itemId) {
      if (this.itemMultipleExpanded) {
        Vue.set(this.expandedItems, itemId, !this.expandedItems[itemId]);
      } else {
        this.items.forEach(item => {
          if (item[this.itemKey] === itemId) {
            Vue.set(
              this.expandedItems,
              item[this.itemKey],
              !this.expandedItems[item[this.itemKey]],
            );
          } else {
            Vue.set(this.expandedItems, item[this.itemKey], false);
          }
        });
      }
    },
    filterItems(items, refinement) {
      if (!refinement.filter) return items;
      items = items.filter(refinement.filter);
      items.forEach(item => {
        this.refinementMatches[item[this.itemKey]] = [refinement];
      });
      return items;
    },
    filterItemsForEach(items, refinements) {
      if (refinements.length === 0) return items;
      //Filter items based on refinements and track which items matched with each refinement
      return items.reduce((filteredItems, item) => {
        this.updateMatches(item, refinements);
        //Include item if one refinement matches
        if (this.refinementInclusive) {
          if (this.refinementMatches[item[this.itemKey]].length !== 0) {
            filteredItems.push(item);
          }
        }
        //Include item if all refinements matches
        else {
          if (this.refinementMatches[item[this.itemKey]].length === refinements.length) {
            filteredItems.push(item);
          }
        }
        return filteredItems;
      }, []);
    },
    /**
     * Checks which refinements match the item and then updates refinementMatches for that item.
     * I.e. each matching refinement is pushed to refinementMatches under that item.
     * @param {Object} item
     * @param {Object[]} refinements
     * @param {Function} refinements[].filter is a filter function that returns true if the given
     * item match that refinement
     */
    updateMatches(item, refinements) {
      this.refinementMatches[item[this.itemKey]] = refinements.reduce((matches, refinement) => {
        if (refinement.filter(item)) {
          matches.push(refinement);
        }
        return matches;
      }, []);
    },
    clearMatches(items) {
      items.forEach(item => {
        this.refinementMatches[item[this.itemKey]] = [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Panel {
  display: flex;
  flex-direction: column;

  .Panel__refinements {
    margin-bottom: 10px;
  }

  .Panel__items {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}
</style>
