export const stringArraysEqual = (a, b) => {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const uniqueArray = (array, isEqual = (a, b) => a === b) => {
  return array.filter((a, index) => {
    return index === array.findIndex(b => isEqual(a, b));
  });
};

export const groupIntoObject = (array, getGroupKey) => {
  return array.reduce((object, item) => {
    const key = getGroupKey(item);
    if (!object[key]) {
      object[key] = [];
    }
    object[key].push(item);
    return object;
  }, {});
};

export const objectEntriesToArray = (object, keyProperty = 'key', valueProperty = 'value') => {
  return Object.keys(object).map(key => {
    return {
      [keyProperty]: key,
      [valueProperty]: object[key],
    };
  });
};

export const groupBy = (array, getGroupKey, groupKeyName = 'key', groupValueName = 'values') => {
  return objectEntriesToArray(groupIntoObject(array, getGroupKey), groupKeyName, groupValueName);
};

export const filterByQuery = (array, query, searchableAttributes) => {
  if (!array || array.length === 0 || !query) return array;
  return array.filter(item => {
    if (searchableAttributes === undefined) {
      if (typeof item === 'object')
        return Object.values(item).some(property => contains(property, query));
      return contains(item, query);
    }
    return searchableAttributes.some(path => {
      return matches(path, query, item);
    });
  });
};

const contains = (value, type, query) => {
  if (type !== 'string' && type !== 'number') return false;
  return value
    .toString()
    .toLowerCase()
    .includes(query.toLowerCase());
};

const matches = (path, query, node) => {
  const i = path.indexOf('.');
  const isLast = i === -1;
  const nextNode = isLast ? node[path] : node[path.substring(0, i)];
  const type = Array.isArray(nextNode) ? 'array' : typeof nextNode;
  if (isLast) {
    switch (type) {
      case 'object':
        return Object.values(nextNode).some(item => contains(item, type, query));
      case 'array':
        return nextNode.some(item => contains(item, type, query));
      default:
        return contains(nextNode, type, query);
    }
  } else {
    const restPath = path.substring(i + 1);
    switch (type) {
      case 'object':
        return matches(restPath, query, nextNode);
      case 'array':
        return nextNode.some(_nextNode => matches(restPath, query, _nextNode));
      default:
        return false;
    }
  }
};
