import SpecialDeliveryModule, { Getters, Actions } from '@store/modules/specialdelivery';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'SpecialDeliveryContainer',

  props: {
    filter: {
      type: Function,
      default: undefined,
    },
  },

  computed: {
    ...mapState('specialdelivery', ['containerStatus', 'containers', 'departureStatus']),
    ...mapGetters('specialdelivery', {
      subscribedDepartures: Getters.SUBSCRIBED_DEPARTURES,
      scheduledDepartures: Getters.SCHEDULED_DEPARTURES,
      departmentNames: Getters.DEPARTMENT_NAMES,
      subscribedDeparturesByDepartments: Getters.SUBSCRIBED_DEPARTURES_BY_DEPARTMENTS,
      scheduledDeparturesByDepartments: Getters.SCHEDULED_DEPARTURES_BY_DEPARTMENTS,
      nextScheduledDepartures: Getters.NEXT_SCHEDULED_DEPARTURES,
    }),
    filteredContainers() {
      if (this.filter) return this.containers.filter(this.filter);
      return this.containers;
    },
  },

  created() {
    if (!this.$store.hasModule('specialdelivery')) {
      this.$store.registerModule('specialdelivery', SpecialDeliveryModule);
    }
    this.fetchContainers();
    this.fetchDeparturesRecursively();
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    ...mapActions('specialdelivery', {
      fetchContainers: Actions.FETCH_CONTAINERS,
      fetchDepartures: Actions.FETCH_DEPARTURES,
    }),
    fetchDeparturesRecursively() {
      this.fetchDepartures(true)
        .then(() => {
          const timeLeft = new Date(this.scheduledDepartures[0].deadline).getTime() - Date.now();
          this.timeout = setTimeout(this.fetchDeparturesRecursively, timeLeft);
        })
        .catch(error => {});
    },
  },

  render() {
    return this.$scopedSlots.default({
      containerStatus: this.containerStatus,
      containers: this.filteredContainers,
      departureStatus: this.departureStatus,
      subscribedDepartures: this.subscribedDepartures,
      scheduledDepartures: this.scheduledDepartures,
      departmentNames: this.departmentNames,
      subscribedDeparturesByDepartments: this.subscribedDeparturesByDepartments,
      scheduledDeparturesByDepartments: this.scheduledDeparturesByDepartments,
      nextScheduledDepartures: this.nextScheduledDepartures,
    });
  },

  provide() {
    return {
      specialDeliveryContainer: this,
    };
  },
};
