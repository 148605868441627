var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SkeletonTransition',{attrs:{"ready":_vm.departures.length !== 0 ||
      _vm.specialDeliveryContainer.departureStatus === _vm.Progress.COMPLETE ||
      _vm.specialDeliveryContainer.departureStatus === _vm.Progress.ERROR},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('Panel',{staticClass:"ScheduledDepartures",attrs:{"items":_vm.departureDates,"item-key":"date","item-color":_vm.Modes.GREY,"item-first-expanded":"","refinement-key":"label"},scopedSlots:_vm._u([{key:"itemContent",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"ScheduledDepartures__panelDate"},[_c('Time',{staticClass:"ScheduledDepartures__panelDay",attrs:{"datetime":item.date,"format":"dddd"}}),_c('span',[_vm._v(", ")]),_c('Time',{attrs:{"datetime":item.date,"format":"LL"}}),(_vm.isToday(item.date))?_c('span',{staticClass:"ScheduledDepartures__panelDateLabel"},[_vm._v(" "+_vm._s(("(" + (_vm.$t('today')) + ")"))+" ")]):_vm._e()],1),_c('span',{staticClass:"ScheduledDepartures__panelTimes"},[_vm._v(" "+_vm._s(item.departures.length)+" "+_vm._s(_vm.$tc('departures', item.departures.length))+" ")])]}},{key:"itemExpansion",fn:function(ref){
      var item = ref.item;
return [_c('ResizeObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var width = ref.width;
return [(width > 800)?_c('table',{staticClass:"ScheduledDepartures__table"},[_c('tr',[_c('th',{staticClass:"ScheduledDepartures__label"},[_vm._v(_vm._s(_vm.$t('departure')))]),_c('th',{staticClass:"ScheduledDepartures__label"},[_vm._v(_vm._s(_vm.$t('deadline')))]),_c('th',{staticClass:"ScheduledDepartures__label"},[_vm._v(_vm._s(_vm.$t('departure_name')))])]),_vm._l((item.departures),function(departure,index){return _c('tr',{key:index,staticClass:"ScheduledDepartures__row"},[_c('td',{staticClass:"ScheduledDepartures__date"},[_c('Time',{attrs:{"datetime":departure.date,"format":"LT"}})],1),_c('td',{staticClass:"ScheduledDepartures__deadline"},[_c('Time',{attrs:{"datetime":departure.deadline,"format":"LT"}}),(_vm.isNextDeparture(departure))?_c('Countdown',{staticClass:"ScheduledDepartures__countdown",attrs:{"to":departure.deadline}}):_vm._e()],1),_c('td',{staticClass:"ScheduledDepartures__departmentName"},[_vm._v(" "+_vm._s(departure.departure_department.name)+" ")])])})],2):_vm._l((item.departures),function(departure,index){return _c('div',{key:index,staticClass:"ScheduledDepartures__row ScheduledDepartures__departureCard"},[_c('DepartureCard',{attrs:{"department":departure.departure_department.name,"departure-date":departure.date,"deadline-date":departure.deadline,"show-countdown":_vm.isNextDeparture(departure)}})],1)})]}}],null,true)})]}}])})]},proxy:true},{key:"skeleton",fn:function(){return [_c('PanelSkeleton',{attrs:{"refinements":0,"item-color":_vm.Modes.GREY},scopedSlots:_vm._u([{key:"itemContent",fn:function(){return [_c('SkeletonGraphic',{attrs:{"width":200,"height":21}})]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }