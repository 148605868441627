<template>
  <component :is="tag" class="DetailList">
    <figure v-if="image || icon" class="DetailList__figure">
      <ImageComponent
        v-if="image"
        :src="image"
        :alt="title"
        :aspect-ratio="imageAspectRatio"
        :style="style"
      />
      <i v-else-if="icon" :class="icon" class="DetailList__icon" />
    </figure>
    <ul class="DetailList__lines">
      <li class="DetailList__title">{{ title }}</li>
      <ColonListItem
        v-for="(line, index) in filteredLines"
        :key="index"
        tag="li"
        :label="line.label"
        :value="line.value"
        :format="line.format"
        :url-placeholder="line.title"
        :date-format="line.dateFormat"
        class="DetailList__line"
      />
    </ul>
  </component>
</template>

<script>
import ImageComponent from '@components/ImageComponent';
import ColonListItem from '@scenes/SelfserviceScene/components/ColonListItem';

export default {
  name: 'DetailList',

  components: {
    ImageComponent,
    ColonListItem,
  },

  props: {
    tag: {
      type: String,
      default: 'li',
    },
    title: {
      type: String,
      default: '',
    },
    lines: {
      type: Array,
      default() {
        return [];
      },
    },
    image: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageAspectRatio: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },

  computed: {
    filteredLines() {
      return this.lines.filter(function(item) {
        return typeof item === 'object';
      });
    },

    style() {
      return {
        width: typeof value === 'number' ? `${this.width}px` : this.width,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.DetailList {
  display: flex;

  .DetailList__figure {
    padding: 0.3em;
    margin-right: 0.6em;
  }

  .DetailList__icon {
    font-size: 2em;
    color: #333;
  }

  .DetailList__lines {
    margin: 0;
  }

  .DetailList__line {
    font-size: 0.85em;
    color: #999;
  }

  .DetailList__title {
    color: #333;
    font-weight: bold;
  }
}
</style>
