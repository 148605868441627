var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SpecialDeliveryContainer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var containerStatus = ref.containerStatus;
var containers = ref.containers;
return [_c('div',{staticClass:"SpecialDeliveryScene"},[_c('section',{staticClass:"SpecialDeliveryScene__section"},[_c('h3',{staticClass:"SpecialDeliveryScene__headline"},[_vm._v(_vm._s(_vm.$t('departureTimes')))]),_c('SubscribedDepartures',{staticClass:"SpecialDeliveryScene__subscribedDepartures"})],1),_c('section',{staticClass:"SpecialDeliveryScene__section"},[_c('h3',{staticClass:"SpecialDeliveryScene__headline"},[_vm._v(_vm._s(_vm.$t('upcomingDelivery')))]),_c('ScheduledDepartures',{staticClass:"SpecialDeliveryScene__scheduledDepartures"})],1),_c('section',[_c('h3',{staticClass:"SpecialDeliveryScene__headline"},[_vm._v(_vm._s(_vm.$t('yourContainer')))]),_c('ul',{staticClass:"SpecialDeliveryScene__containers"},_vm._l((containers),function(item){return _c('DetailList',{key:item.container_id,attrs:{"icon":"fal fa-inbox","title":_vm.$t('number') + ': ' + item.container_id,"lines":[
            {
              label: _vm.$t('receive'),
              value: item.date_received,
              format: _vm.FormatTypes.DATE,
            },
            item.department && {
              label: _vm.$t('department'),
              value: item.department,
            } ]}})}),1),(
          containers.length === 0 &&
            (containerStatus === _vm.Progress.COMPLETE || containerStatus === _vm.Progress.ERROR)
        )?_c('p',[_vm._v(" "+_vm._s(_vm.$t('missingBox'))+" ")]):_vm._e()])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }