<template>
  <span
    :class="[
      'Countdown',
      isAlmostUp && 'Countdown--almostUp',
      isRunningOut && 'Countdown--runningOut',
    ]"
  >
    <i class="Countdown__icon fas fa-clock" />
    <span v-if="totalDays > 0">
      {{ $tc('date.day--n_plural', totalDays) }}
    </span>
    <span v-if="convertToTime.hours > 0 || totalDays > 0">
      {{ $tc('time.hour--n_plural', convertToTime.hours) }}
    </span>
    <span v-if="convertToTime.minutes > 0 || convertToTime.hours > 0 || totalDays > 0">
      {{ $t('time.minute--short', { n: convertToTime.minutes }) }}
    </span>
    <span v-if="totalDays < 1">
      {{ $t('time.second--short', { n: convertToTime.seconds }) }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'Countdown',

  props: {
    to: {
      type: String,
      required: true,
    },
    refreshInterval: {
      type: Number,
      default: 1000,
    },
    timeAlmostUp: {
      type: Number,
      default: 30,
    },
    timeRunningOut: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      dateNow: null,
    };
  },

  computed: {
    isAlmostUp() {
      return this.totalMinutes < this.timeAlmostUp && !this.isRunningOut;
    },
    isRunningOut() {
      return this.totalMinutes < this.timeRunningOut;
    },
    toDate() {
      return new Date(this.to);
    },
    timeLeft() {
      const timeLeft = this.toDate - this.dateNow;
      return timeLeft > 0 ? timeLeft : 0;
    },
    totalSeconds() {
      return Math.floor(this.timeLeft / 1000);
    },
    totalMinutes() {
      return Math.floor(this.totalSeconds / 60);
    },
    totalHours() {
      return Math.floor(this.totalMinutes / 60);
    },
    totalDays() {
      return Math.floor(this.totalHours / 24);
    },
    convertToTime() {
      return {
        seconds: this.totalSeconds % 60,
        minutes: this.totalMinutes % 60,
        hours: this.totalHours % 24,
      };
    },
  },

  watch: {
    timeLeft(to, from) {
      if (to !== from && this.timeLeft <= 0) {
        this.countdownReached();
      }
    },
    to: {
      handler() {
        if (this.timeLeft === 0) {
          this.countdownReached();
        }
      },
      immediate: true,
    },
  },

  created() {
    this.updateNow();
  },

  mounted() {
    this.updateInterval = setInterval(this.updateNow, this.refreshInterval);
  },

  beforeDestroy() {
    clearInterval(this.updateInterval);
  },

  methods: {
    updateNow() {
      this.dateNow = Date.now();
    },
    countdownReached() {
      this.$emit('complete');
    },
  },
};
</script>

<style lang="scss" scoped>
.Countdown {
  display: flex;
  align-items: center;
  gap: 0.6em;

  .Countdown__icon {
    color: #5db652;
  }

  &--almostUp .Countdown__icon {
    color: #ffcc00;
  }

  &--runningOut .Countdown__icon {
    color: #ff0000;
  }
}
</style>
