<template>
  <SkeletonTransition
    :ready="
      specialDeliveryContainer.subscribedDepartures.length !== 0 ||
        specialDeliveryContainer.departureStatus === Progress.COMPLETE ||
        specialDeliveryContainer.departureStatus === Progress.ERROR
    "
  >
    <template #default>
      <!-- Temporarily removed because we no longer want departures grouped by departments-->
      <!-- <ul class="SubscribedDepartures">
        <li v-for="department in subscribedDeparturesByDepartments" :key="department.name">
          <p
            v-if="subscribedDeparturesByDepartments.length > 1"
            class="SubscribedDepartures__departmentName"
          >
            {{ department.name }}
          </p>
          <ul class="SubscribedDepartures__departures">
            <li
              v-for="item in department.departures"
              :key="item.date"
              class="SubscribedDepartures__departure"
            >
              <Time :datetime="item.date" format="LT" />
            </li>
          </ul>
        </li>
      </ul> -->
      <ul class="SubscribedDepartures">
        <ul class="SubscribedDepartures__departures">
          <li
            v-for="item in filterDuplicateDates(specialDeliveryContainer.subscribedDepartures)"
            :key="item.date"
            class="SubscribedDepartures__departure"
          >
            <Time :datetime="item.date" format="LT" />
          </li>
        </ul>
      </ul>
    </template>
    <template #skeleton>
      <ul class="SubscribedDepartures">
        <ul class="SubscribedDepartures__departures">
          <SkeletonGraphic
            v-for="i in 4"
            :key="i"
            :width="100"
            :height="43"
            class="SubscribedDepartures__departure"
          />
        </ul>
      </ul>
    </template>
  </SkeletonTransition>
</template>

<script>
import { uniqueArray } from '@helpers/array';
import Time from '@components/Time';
import { SkeletonTransition, SkeletonGraphic } from '@components/Skeleton';
import Progress from '@types/Progress';

export default {
  name: 'SubscribedDepartures',

  components: {
    Time,
    SkeletonTransition,
    SkeletonGraphic,
  },

  enums: {
    Progress,
  },

  methods: {
    filterDuplicateDates(departures) {
      return uniqueArray(departures, (a, b) => a.date === b.date);
    },
  },

  inject: {
    specialDeliveryContainer: {
      default() {
        console.error('Missing SpecialDeliveryContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.SubscribedDepartures {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 10px;
  margin: 0;

  .SubscribedDepartures__departmentName {
    font-size: 0.9em;
  }

  .SubscribedDepartures__departures {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6em 0.4em;
    font-weight: 500;
  }

  .SubscribedDepartures__departure {
    font-size: 1em;
    color: #333;
    background-color: #f6f6f6;
    padding: 0.5em 1.5em;
  }
}
</style>
