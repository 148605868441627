<template>
  <SkeletonTransition
    :ready="
      departures.length !== 0 ||
        specialDeliveryContainer.departureStatus === Progress.COMPLETE ||
        specialDeliveryContainer.departureStatus === Progress.ERROR
    "
  >
    <template #default>
      <Panel
        :items="departureDates"
        item-key="date"
        :item-color="Modes.GREY"
        item-first-expanded
        refinement-key="label"
        class="ScheduledDepartures"
      >
        <template #itemContent="{ item }">
          <span class="ScheduledDepartures__panelDate">
            <Time :datetime="item.date" format="dddd" class="ScheduledDepartures__panelDay" />
            <span>, </span>
            <Time :datetime="item.date" format="LL" />
            <span class="ScheduledDepartures__panelDateLabel" v-if="isToday(item.date)">
              {{ `(${$t('today')})` }}
            </span>
          </span>
          <span class="ScheduledDepartures__panelTimes">
            {{ item.departures.length }} {{ $tc('departures', item.departures.length) }}
          </span>
        </template>
        <template #itemExpansion="{ item }">
          <ResizeObserver v-slot="{ width }">
            <table v-if="width > 800" class="ScheduledDepartures__table">
              <tr>
                <th class="ScheduledDepartures__label">{{ $t('departure') }}</th>
                <th class="ScheduledDepartures__label">{{ $t('deadline') }}</th>
                <th class="ScheduledDepartures__label">{{ $t('departure_name') }}</th>
              </tr>
              <tr
                v-for="(departure, index) in item.departures"
                :key="index"
                class="ScheduledDepartures__row"
              >
                <td class="ScheduledDepartures__date">
                  <Time :datetime="departure.date" format="LT" />
                </td>
                <td class="ScheduledDepartures__deadline">
                  <Time :datetime="departure.deadline" format="LT" />
                  <Countdown
                    v-if="isNextDeparture(departure)"
                    :to="departure.deadline"
                    class="ScheduledDepartures__countdown"
                  />
                </td>
                <td class="ScheduledDepartures__departmentName">
                  {{ departure.departure_department.name }}
                </td>
              </tr>
            </table>

            <div
              v-for="(departure, index) in item.departures"
              v-else
              :key="index"
              class="ScheduledDepartures__row ScheduledDepartures__departureCard"
            >
              <DepartureCard
                :department="departure.departure_department.name"
                :departure-date="departure.date"
                :deadline-date="departure.deadline"
                :show-countdown="isNextDeparture(departure)"
              />
            </div>
          </ResizeObserver>
        </template>
      </Panel>
    </template>
    <template #skeleton>
      <PanelSkeleton :refinements="0" :item-color="Modes.GREY">
        <template #itemContent>
          <SkeletonGraphic :width="200" :height="21" />
        </template>
      </PanelSkeleton>
    </template>
  </SkeletonTransition>
</template>

<script>
import { isToday } from '@helpers/date';
import { groupBy } from '@helpers/array';
import Panel from '@scenes/SelfserviceScene/components/Panel';
import { Modes } from '@scenes/SelfserviceScene/components/Panel/types';
import { SkeletonTransition, SkeletonGraphic, PanelSkeleton } from '@components/Skeleton';
import Time from '@components/Time';
import Countdown from './components/Countdown';
import DepartureCard from './components/DepartureCard';
import ResizeObserver from '@components/ResizeObserver';
import Progress from '@types/Progress';

export default {
  name: 'ScheduledDepartures',

  components: {
    Time,
    Panel,
    SkeletonTransition,
    SkeletonGraphic,
    PanelSkeleton,
    Countdown,
    DepartureCard,
    ResizeObserver,
  },

  enums: {
    Modes,
    Progress,
  },

  props: {
    departures: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    sortedDepartures() {
      return [...this.specialDeliveryContainer.scheduledDepartures].sort(
        (a, b) => new Date(a.date) - new Date(b.date),
      );
    },
    departureDates() {
      return groupBy(
        this.sortedDepartures,
        departure => departure.date.split('T')[0],
        'date',
        'departures',
      );
    },
    /**
     * A list of refinement objects, each containing a label and a filter. Some refinements are created from
     * the unique departments among departures.
     * @returns {Object[]}
     */
    // Removed because we no longer want to filter by departments.
    // refinements() {
    //   const refinements = [
    //     { label: 'Alle', reset: true },
    //     ...this.specialDeliveryContainer.departmentNames.map(department => {
    //       return {
    //         label: department,
    //         filter(date) {
    //           return date.departures.some(
    //             departure => departure.departure_department.name === department,
    //           );
    //         },
    //       };
    //     }),
    //   ];
    //   return refinements.length > 2 ? refinements : [];
    // },
  },

  methods: {
    isToday(date) {
      return isToday(date);
    },
    // Removed because we no longer want to filter by departments. 2nd arg used refinementMatches
    // filteredDepartures(departures, refinements) {
    //   if (refinements.length === 0) return departures;
    //   return departures.filter(departure => {
    //     return refinements.some(
    //       refinement => departure.departure_department.name === refinement.label,
    //     );
    //   });
    // },
    isNextDeparture(departure) {
      return this.specialDeliveryContainer.nextScheduledDepartures.some(nextDeparture => {
        return (
          nextDeparture.departure_department.name === departure.departure_department.name &&
          nextDeparture.date === departure.date
        );
      });
    },
  },

  inject: {
    specialDeliveryContainer: {
      default() {
        console.error('Missing SpecialDeliveryContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ScheduledDepartures {
  .ScheduledDepartures__panelDate {
    white-space: nowrap;
  }

  .ScheduledDepartures__panelDay {
    text-transform: capitalize;
  }

  .ScheduledDepartures__panelTimes {
    white-space: nowrap;
    color: #999;
    margin-right: 15px;
  }

  .ScheduledDepartures__panelDateLabel {
    margin-left: 0.5em;
  }

  .ScheduledDepartures__table {
    table-layout: fixed;
    width: 100%;
    margin: 0 20px;
  }

  .ScheduledDepartures__label {
    font-size: 12px;
    font-weight: normal;
    color: #999;
  }

  .ScheduledDepartures__row {
    border-bottom: 1px solid #f6f6f6;
    &:last-child {
      border-bottom: none;
    }
  }

  .ScheduledDepartures__date {
    font-weight: 500;
    font-size: 16px;
    padding: 12px 0px;
    color: #333;
  }

  .ScheduledDepartures__deadline {
    display: flex;
    font-size: 16px;
    padding: 12px 0px;
    color: #333;
  }

  .ScheduledDepartures__countdown {
    margin-left: 16px;
  }

  .ScheduledDepartures__departmentName {
    font-size: 16px;
    padding: 12px 0px;
    color: #333;
  }

  .ScheduledDepartures__deadline {
    display: flex;
  }

  .ScheduledDepartures__departureCard {
    padding: 15px 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:only-child {
      padding: 0;
    }
  }
}
</style>

<i18n lang="json">
{
  "da": {
    "today": "i dag",
    "deadline": "Deadline for bestilling",
    "departure": "Afgang",
    "departure_name": "Afgang fra",
    "nextDeparture": "Næste varetur",
    "departures": "varetur | vareture",
    "expectedArrival":"Forventet ankomst"
  }
}
</i18n>
