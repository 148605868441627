<template>
  <component
    :is="tag"
    :class="[
      'PanelItem',
      hasExpansionSlot && 'PanelItem--clickable',
      isExpanded && 'PanelItem--expanded',
      'PanelItem--' + color,
    ]"
  >
    <div class="PanelItem__main" @click="hasExpansionSlot && expand()">
      <div class="PanelItem__content">
        <slot name="default" />
      </div>
      <div class="PanelItem__controls">
        <slot name="controls" />
        <i
          v-if="!hasControlsSlot && hasExpansionSlot"
          class="PanelItem__icon far fa-chevron-down"
        />
      </div>
    </div>
    <Collapse>
      <div v-show="isExpanded">
        <div class="PanelItem__expansion">
          <slot name="expansion" />
        </div>
      </div>
    </Collapse>
  </component>
</template>

<script>
import { Modes } from '../../types';
import Collapse from '@transitions/Collapse';

export default {
  name: 'PanelItem',

  components: {
    Collapse,
  },

  props: {
    tag: {
      type: String,
      default: 'li',
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: Modes.LIGHT,
    },
  },

  computed: {
    hasControlsSlot() {
      return !!this.$slots.controls || !!this.$scopedSlots.controls;
    },
    hasExpansionSlot() {
      return !!this.$slots.expansion || !!this.$scopedSlots.expansion;
    },
  },

  methods: {
    expand() {
      this.$emit('expand', this.$vnode.key);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin colorScheme(
  $modifier,
  $font-color,
  $color,
  $hover-color,
  $expanded-color,
  $top-border-color
) {
  &--#{$modifier} .PanelItem__main {
    color: $font-color;
    background-color: $color;
  }
  &--#{$modifier}#{&}--clickable .PanelItem__main:hover {
    background-color: $hover-color;
  }
  &--#{$modifier}#{&}--expanded .PanelItem__main {
    background-color: $expanded-color;
  }
  &--#{$modifier}#{&} {
    border-top: 2px solid $top-border-color;
  }
}

.PanelItem {
  display: flex;
  flex-direction: column;

  @include colorScheme('light', #333333, #ffffff, #f6f6f6, #ffffff, #f6f6f6);
  @include colorScheme('grey', #333333, #f6f6f6, #f9f9f9, #efefef, #ffffff);

  &:first-child {
    border-top: 0px solid #ffffff;
  }

  &--clickable .PanelItem__main {
    cursor: pointer;
  }

  .PanelItem__main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 20px;
  }

  .PanelItem__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
  }

  &--expanded .PanelItem__content {
    font-weight: bold;
  }

  .PanelItem__controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .PanelItem__icon {
    font-size: 18px;
    transition: transform 0.3s ease-in-out;
  }

  &--expanded .PanelItem__icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  .PanelItem__expansion {
    padding: 20px;
  }
}
</style>
